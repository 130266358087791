import React from 'react'
import { Link } from 'gatsby'
import { Button, Row, Col, Container, Table } from 'react-bootstrap'
import { useState, useEffect, useMemo } from 'react'
import firebase from 'firebase'
import { useCallback } from 'react'

const isBrowser = typeof window !== 'undefined'

const Members = ({ docs, doc }) => {
  const [searchName, setSearchName] = useState('')
  const [searchCountry, setSearchCountry] = useState('')
  const [searchArea, setSearchArea] = useState('')
  const [searchStatus, setSearchStatus] = useState('')
  const [uniqueAreasArray, setUniqueAreasArray] = useState([])
  const [uniqueStatusArray, setUniqueStatusArray] = useState([])
  const [allRegisteredUsersFromFirebase, setAllRegisteredUsersFromFirebase] =
    useState([])

  useEffect(() => {
    let areasArray = []
    docs.nodes.filter((node) => {
      return areasArray.push(node.data.area.text)
    })

    let uniqueAreasArray = [...new Set(areasArray)]
    setUniqueAreasArray(uniqueAreasArray)

    let statusArray = []
    docs.nodes.filter((node) => {
      return statusArray.push(node.data.status)
    })

    let uniqueStatusArray = [...new Set(statusArray)]
    setUniqueStatusArray(uniqueStatusArray)
  }, [docs.nodes])

  useEffect(() => {
    let isMounted = true
    if (isBrowser && isMounted) {
      try {
        firebase
          .firestore()
          .collection('usersCollection')
          .onSnapshot((snapshot) => {
            const listUsers = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            const registeredUsers = listUsers.filter(
              (user) => user?.currentStatus === 'Registered',
            )
            setAllRegisteredUsersFromFirebase(registeredUsers)
          })
      } catch (error) {
        console.log(error)
      }
    }
    return () => {
      if (isBrowser && isMounted) {
        try {
          firebase
            .firestore()
            .collection('usersCollection')
            .onSnapshot((snapshot) => {
              const listUsers = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
            })
        } catch (error) {
          console.log(error)
        }
        isMounted = false
      }
    }
  }, [])

  const allRegisteredUsers = allRegisteredUsersFromFirebase
    .concat(docs.nodes)
    .sort((a, b) => a.data?.name?.text.localeCompare(b.data?.name?.text))

  // const sortByAToZ = (todoList) => {
  //   return todoList?.sort((a, b) => a.content.localeCompare(b.content))
  // }
  // console.log(docs.nodes.length, 'all docs')
  // console.log(allRegisteredUsersFromFirebase.length, 'all fire')
  // console.log(allRegisteredUsers.length, 'all users')

  const filteredArray = allRegisteredUsers?.filter((user) => {
    const searchByName =
      searchName === ''
        ? user
        : user?.data?.name?.text
            .toLowerCase()
            .includes(searchName.toLowerCase())

    const searchByCountry =
      searchCountry === ''
        ? user
        : user?.data?.country
            ?.toLowerCase()
            .includes(searchCountry.toLowerCase())

    const searchByArea =
      searchArea === ''
        ? user
        : user?.data?.area?.text
            ?.toLowerCase()
            .includes(searchArea.toLowerCase())

    // const searchByStatus =
    //   searchStatus === ''
    //     ? user
    //     : user?.data?.status?.toLowerCase().includes(searchStatus.toLowerCase())

    const searchByActivityStatus = () => {
      if (searchStatus === '') {
        return user
      } else if (searchStatus === 'Active') {
        return (
          user?.activityStatus ||
          user?.data?.status?.toLowerCase().includes(searchStatus.toLowerCase())
        )
      } else {
        return (
          ! user?.activityStatus ||
          user?.data?.status?.toLowerCase().includes(searchStatus.toLowerCase())
        )
      }
    }

    return (
      searchByName &&
      searchByCountry &&
      searchByArea &&
      // searchByStatus &&
      searchByActivityStatus()
    )
  })
  // console.log(filteredArray.length, 'all filtered users')

  return (
    <Container fluid>
      <Row className="mt-2 mb-5 mx-3 shadow p-3 bg-body rounded">
        <h5>{doc.registered_educators_collection_page[0].search_label}:</h5>
        <Col className="input-section-filter_wrapper" xs={12} md={3}>
          <label className="invisible" htmlFor="filterName">
            by {doc.registered_educators_collection_page[0].full_name_label}
          </label>
          <input
            type="text"
            className="input-section-filter"
            id="filterName"
            placeholder={
              doc.registered_educators_collection_page[0].search_by_name_label
            }
            onChange={(event) => {
              setSearchName(event.target.value)
            }}
          />
        </Col>
        <Col className="input-section-filter_wrapper" xs={12} md={3}>
          <label className="invisible" htmlFor="filterCountry">
            by {doc.registered_educators_collection_page[0].country_label}
          </label>
          <input
            type="text"
            className="input-section-filter"
            id="filterCountry"
            placeholder={
              doc.registered_educators_collection_page[0]
                .search_by_country_label
            }
            onChange={(event) => {
              setSearchCountry(event.target.value)
            }}
          />
        </Col>
        <Col className="input-section-filter_wrapper" xs={12} md={3}>
          <label className="invisible" htmlFor="areaOfExp">
            by{' '}
            {
              doc.registered_educators_collection_page[0]
                .area_of_expertise_in_table_label
            }
          </label>
          <select
            name="areaOfExp"
            id="areaOfExp"
            onChange={(event) => {
              setSearchArea(event.target.value)
            }}
          >
            <option value="">
              {
                doc.registered_educators_collection_page[0]
                  .search_by_area_of_exp_label
              }
            </option>
            {uniqueAreasArray.map((area, key) => (
              <option key={key} value={area}>
                {area}
              </option>
            ))}
          </select>
        </Col>
        <Col className="input-section-filter_wrapper" xs={12} md={3}>
          <label className="invisible" htmlFor="status">
            by {doc.registered_educators_collection_page[0].status_label}
          </label>
          <select
            name="status"
            id="status"
            onChange={(event) => {
              setSearchStatus(event.target.value)
            }}
          >
            <option value="">
              by {doc.registered_educators_collection_page[0].status_label} ...
            </option>
            <option>Not Active</option>
            <option>Active</option>
            {/* {uniqueStatusArray.map((area, key) => (
              <option key={key} value={area}>
                {area}
              </option>
            ))} */}
          </select>
        </Col>
      </Row>
      <Row xs={1} className="mt-2 mb-5 mx-3">
        <Col>
          <Table bordered hover responsive>
            <thead>
              <tr className="bg-secondary bg-gradient text-white">
                <th className="align-middle text-center">
                  {doc.registered_educators_collection_page[0].full_name_label}
                </th>
                <th className="align-middle text-center">
                  {doc.registered_educators_collection_page[0].country_label}
                </th>
                <th className="align-middle text-center">
                  {
                    doc.registered_educators_collection_page[0]
                      .area_of_expertise_in_table_label
                  }
                </th>
                <th className="align-middle text-center">
                  {
                    doc.registered_educators_collection_page[0]
                      .registration_number_label
                  }
                </th>
                <th className="align-middle text-center">
                  {doc.registered_educators_collection_page[0].status_label}
                </th>
                <th className="align-middle text-center">
                  {
                    doc.registered_educators_collection_page[0]
                      .additional_information_label1
                  }
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredArray?.map((member, key) => {
                const status =
                  member?.data?.status === 'Active' ||
                  member?.activityStatus === true
                    ? 'activeMem align-middle text-center'
                    : 'notActiveMem align-middle text-center'
                return (
                  <tr key={key}>
                    <td className="align-middle text-center">
                      {member?.data?.name?.text || member?.full_name}
                    </td>
                    <td className="align-middle text-center">
                      {member?.data?.country || member?.country}
                    </td>
                    <td className="align-middle text-center">
                      {member?.data?.area?.text}
                    </td>
                    <td className="align-middle text-center">
                      {member?.data?.code?.text}
                    </td>
                    <td className={status}>
                      {member?.activityStatus
                        ? 'Active'
                        : member?.data?.status || 'Not Active'}
                    </td>
                    <td className="align-middle text-center">
                      <Link
                        to={
                          member?.full_name
                            ? `/enter-educators/professional-portfolio-card?userId=${member.id}`
                            : member?.uid
                        }
                      >
                        <Button
                          style={{ width: '100%' }}
                          variant="outline-primary"
                        >
                          More info
                        </Button>
                      </Link>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}
export default Members
