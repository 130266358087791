import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from '../utils/LinkResolver'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import Members from '../components/Members-components/REMembers-component'

const AllRE = ({ data }) => {
  if (!data) return null

  const doc = data.prismicCollectionPage.data
  const docs = data.allPrismicRemember
  const pageContent = data.prismicCollectionPage
  const { lang, type, url } = pageContent
  const page = pageContent.data || {}
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicMenuLink || {}
  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <Seo title={page.title.text} lang={lang} />
      <h1 className="news-title">{doc.title.text} </h1>
      {doc.description.text && (
        <p className="m-3 mb-5 text-center">{doc.description.text}</p>
      )}
      <Members docs={docs} doc={doc} />
    </Layout>
  )
}

export const query = graphql`
  query reMembersCollectionPageQuery($lang: String) {
    prismicCollectionPage(
      uid: { eq: "enter-registered-educators" }
      lang: { eq: $lang }
    ) {
      alternate_languages {
        uid
        type
        lang
      }
      type
      url
      lang
      _previewable
      data {
        registered_educators_collection_page {
          additional_information_button_label
          additional_information_label
          search_by_area_of_exp_label
          search_by_country_label
          search_by_name_label
          search_label
          additional_information_label1
          area_of_expertise_in_table_label
          country_label
          full_name_label
          registration_number_label
          status_label
        }
        title {
          text
        }
        description {
          text
          raw
        }
        links {
          background_image {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              width: 321
              height: 256
            )
          }
          link {
            url
          }
          link_title {
            text
          }
        }
      }
    }
    allPrismicRemember(
      filter: { lang: { eq: $lang } }
      sort: { order: ASC, fields: data___name___text }
    ) {
      nodes {
        _previewable
        alternate_languages {
          id
          lang
        }
        id
        lang
        tags
        uid
        data {
          area {
            text
          }
          code {
            text
          }
          e_mail {
            text
          }
          id {
            text
          }
          membership_grade {
            text
          }
          name {
            text
          }
          phone {
            text
          }
          qr_code {
            text
          }
          registration_date(formatString: "DD/MM/YYYY")
          status_valid_until(formatString: "DD/MM/YYYY")
          university {
            text
          }
          status
          country
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`

export default withPrismicPreview(AllRE, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
